.IconComponent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease;

    &.clickable {
        &:hover {
            cursor: pointer;
        }
    }
}

.IconComponent_svgWrapper {
    transition: all 500ms ease;
    pointer-events: none;
}
