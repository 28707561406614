.FinToggle_frame {
    // margin-top: 10px;
    width: 60px;
    height: 30px;
    background-color: limegreen;
    border-radius: 25px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;

    &.false {
        background-color: grey;
    }
}
.FinToggle_bubble {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 22px;

    top: 3px;
    width: 35px;
    height: 24px;
    border-radius: 15px;
    background-color: white;
    color: black;
    transition: 0.5s;
    font-size: 12px;
    font-weight: 700;

    &.false {
        background-color: darkgrey;
        left: 3px;
    }
}
