.SelectBoxOptionsList_container {
    position: absolute;
    width: calc(100% - 40px);
    max-height: 0px;
    z-index: 1000;
    background-color: white;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s ease-in-out;

    &.dark {
        background-color: black;
    }

    &.focus {
        border: 1px solid rgba(125, 125, 125, 1);
        max-height: 200px;
        overflow-y: scroll;
    }

    // &::-webkit-scrollbar {
    //     width: 5px;
    //     background-color: red;
    // }

    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: darkgrey;
    //     outline: 1px solid slategrey;
    // }
}

// .SelectBoxOptionsList_listZone {
// padding: 0 20px;
// }

.SelectBoxOptionsList_singleOption {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
}
