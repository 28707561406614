// @use "V3/Components/scssGlobal/scssGlobal" as g;
// @include g.freeBp(font-size,((0, 500, 10px), (500, 1000, 20px))); // (minW, maxW, value);
// @include g.S {} // 0-767
// @include g.M {} // 767-1440
// @include g.L {} // 1440-1920
// @include g.XL {} // 1920-3840

// @include g.phone {} // 0-360
// @include g.tablet {} // 767-991

// @include g.notSafe {}
// @include g.safe {}

// @include g.customDeviceMedia (S, M, L, XL, phone, tablet){};
// @include g.media(background-color, S, M, L, XL);
// @include g.mediaSafe(background-color, notSafe, safe);

@mixin baseRem {
    @include freeStyleBp(
        font-size,
        (
            (0, 320, 8.12px),
            (320, 478, 8.31px),
            (478, 767, 8.64px),
            (767, 1024, 8.95px),
            (1024, 1360, 9.34px),
            (1360, 1440, 9.43px),
            (1440, 1600, 9.62px),
            (1600, 1920, 10px),
            (1920, 2240, 11px),
            (2240, 2560, 12px),
            (2560, 2880, 13px),
            (2880, 3200, 14px),
            (3200, 3520, 15px),
            (3520, 3840, 16px),
            (3840, 7680, 24px)
        )
    );
}

$bpHorizontalBlock: 500;
$bp: (
    XS: 0,
    S: 767,
    M: 1440,
    L: 1921,
    XL: 3841,
);

// SINGLE BREAKPOINT
@mixin S {
    @media (max-width: #{map-get($bp, "S")}+"px"), (max-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin M {
    @media (min-width: #{map-get($bp, "S")}+"px") and (max-width: #{map-get($bp, "M")}+"px") and (min-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin L {
    @media (min-width: #{map-get($bp, "M")}+"px") and (max-width: #{map-get($bp, "L")}+"px") and (min-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin XL {
    @media (min-width: #{map-get($bp, "L")}+"px") and (min-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
// DEVICE BREAKPOINTS
@mixin phone {
    @media (max-width: 500px), (max-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin tablet {
    @media (min-width: 767px) and (max-width: 991px) and (min-height: $bpHorizontalBlock+"px") {
        @content;
    }
}

// MULTIPLE BREAKPOINTS
@mixin notSafe {
    @media (max-width: #{map-get($bp, "S")}+"px"), (max-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin safe {
    @media (min-width: #{map-get($bp, "S")}+"px") and (min-height: $bpHorizontalBlock+"px") {
        @content;
    }
}
@mixin customDeviceMedia($keys...) {
    @each $key in $keys {
        @if ($key == S) {
            @include S {
                @content;
            }
        }
        @if ($key == M) {
            @include M {
                @content;
            }
        }
        @if ($key == L) {
            @include L {
                @content;
            }
        }
        @if ($key == XL) {
            @include XL {
                @content;
            }
        }
        @if ($key == phone) {
            @include phone {
                @content;
            }
        }
        @if ($key == tablet) {
            @include tablet {
                @content;
            }
        }
    }
}

// SINGLE PROP
@mixin media($prop, $S, $M, $L, $XL) {
    @include S {
        #{$prop}: $S;
    }
    @include M {
        #{$prop}: $M;
    }
    @include L {
        #{$prop}: $L;
    }
    @include XL {
        #{$prop}: $XL;
    }
}
@mixin mediaSafe($prop, $notSafe, $safe) {
    @include notSafe {
        #{$prop}: $notSafe;
    }
    @include safe {
        #{$prop}: $safe;
    }
}
// FREE BP
@mixin freeBp($prop, $obj) {
    @each $bps in $obj {
        @media (min-width: nth($bps, 1)+"px") and (max-width: nth($bps, 2)+"px") {
            #{$prop}: nth($bps, 3);
        }
    }
}
