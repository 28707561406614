.SelectBoxArrowIcon_div {
    position: absolute;
    top: 17px;
    right: 25px;
    width: max-content;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    transform: rotate(0deg);

    &.focus {
        transform: rotate(180deg);
    }
}
