.SelectBoxMultipleSelectionsList_row {
    margin-top: 10px;
    min-height: 50px;
    position: relative;
    &:nth-child(2n + 1) {
        background-color: rgba(125, 125, 125, 0.2);
    }
    display: flex;
    align-items: center;
    user-select: none;

    &.deleteHover {
        background-color: darkred;
    }

    &.light {
        &.deleteHover {
            background-color: pink;
        }
    }
}
.SelectBoxMultipleSelectionsList_text {
    width: 100%;
    padding-left: 20px;
}
.SelectBoxMultipleSelectionsList_icon {
    cursor: pointer;
    margin-right: 7px;
    float: right;
    padding: 5px;
}
