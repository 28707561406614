.FinToggleSmall_frame {
    min-height: 30px;
    width: max-content;
    padding: 2px 10px;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    border: 1px solid white;
    color: white;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;

    &.false {
        opacity: 0.3;
    }

    &.light {
        border: 1px solid black;
        color: black;
    }
}
.FinToggleSmall_icon {
    display: inline-block;
    margin-left: 5px;
}
