.ErrorTextArea {
    max-height: 0;
    transition: all 500ms ease-in-out;
    overflow: hidden;
    font-size: 12px;
    padding: 5px 0;

    &.on {
        max-height: 100px;
        color:#e55353;
    }

    & > span {
        display: block;
    }
}
