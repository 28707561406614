@use "V3/Components/scssGlobal/scssGlobal" as g;
$animDuration: 2s;

.Snackbar_container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 0px;
    height: 0px;
    overflow: hidden;
    animation-fill-mode: forwards;
    z-index: 10001;

    &.on {
        animation-name: example;
        animation-duration: $animDuration;

        @keyframes example {
            0% {
                bottom: 50px;
                right: 50px;
                width: 0px;
                height: 0px;
                border-radius: 50px;
            }
            25%,
            75% {
                bottom: 10px;
                right: 10px;
                width: 80px;
                height: 80px;
                border-radius: 50px;
            }
            100% {
                bottom: 0px;
                right: 0px;
                width: 100vw;
                height: 100px;
                border-radius: 0px;
            }
        }
    }
}
.Snackbar_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 10px;

    &.on {
        animation-name: example5;
        animation-duration: $animDuration;

        @keyframes example5 {
            0%,
            25%,
            75% {
                height: 80px;
            }
            100% {
                height: 100px;
            }
        }
    }
}
.Snackbar_msgIcon {
    // padding-right: 10px;
    width: 100px;
    display: flex;
    justify-content: center;

    &.on {
        & > svg {
            animation-timing-function: cubic-bezier(0.45, -0.55, 0.59, 1.53);
            animation-name: example2;
            animation-duration: $animDuration;

            @keyframes example2 {
                0% {
                    transform: scale(1);
                }
                10% {
                    transform: scale(0.2);
                }
                25% {
                    transform: scale(2.5);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
}
.Snackbar_msg {
    flex: 1;
    opacity: 0;
    animation-fill-mode: forwards;
    overflow: hidden;
    &.on {
        animation-name: example3;
        animation-duration: $animDuration;

        @keyframes example3 {
            85% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: max-content;
            }
        }
    }
}
.Snackbar_closeIcon {
    animation-fill-mode: forwards;
    overflow: hidden;
    opacity: 0;
    width: 0px;
    &.on {
        animation-name: example4;
        animation-duration: $animDuration;

        @keyframes example4 {
            85% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 75px;
            }
        }
    }
}
