.FinInput_input {
    background-color: transparent;
    width: 100%;
    height: 40px;
    border-radius: 0.25rem;
    padding: 0 1rem;
    margin-top: 10px;

    color: white;
    border: 1px solid rgba(125, 125, 125, 1);
    &.light {
        color: black;
        border: 1px solid rgba(175, 175, 175, 1);
    }

    &.error {
        background-color: rgba(255, 0, 0, 0.1);
    }
}
