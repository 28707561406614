.SelectBoxMultipleAllocationList_row {
    margin-top: 10px;
    min-height: 50px;
    position: relative;
    &:nth-child(2n + 1) {
        background-color: rgba(125, 125, 125, 0.2);
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    // user-select: none;

    &.deleteHover {
        background-color: darkred;
    }
    &.deleteHover2 {
        background-color: pink;
    }
    &.editHover {
        background-color: gray;
    }
}
.SelectBoxMultipleAllocationList_text {
    width: 30%;
    max-width: 500px;
    min-width: 300px;
    padding-left: 20px;
}
.SelectBoxMultipleAllocationList_icon {
    cursor: pointer;
    margin-right: 7px;
    float: right;
    padding: 5px;
}

.SelectBoxMultipleAllocationList_text {
    display: flex;
    align-items: center;
    word-break: break-all;
}

.SelectBoxMultipleAllocationList_toggleSmall {
    min-width: 100px;
}
.SelectBoxMultipleAllocationList_sliderArea {
    max-width: 150px;
}
.SelectBoxMultipleAllocationList_ctaLabel {
    padding:10px;
    min-width: 170px;
}
