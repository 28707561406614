.InfoTextArea {
    max-height: 0;
    transition: all 500ms ease-in-out;
    overflow: hidden;
    font-size: 12px;
    padding: 5px 0;
    opacity: 0;

    &.on {
        max-height: 100px;
        opacity: 1;
    }
}
