@use "V3/Components/scssGlobal/scssGlobal" as g;

.PxaLottiePlayer_container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include g.XL {
        & > div {
            width: 100%;
        }
    }
}
