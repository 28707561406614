.FinSliderInput_frame {
    display: flex;
    width: 130px;
    min-height: 50px;
    align-items: center;

    & > span {
        min-width: 30px;
        margin-left: 5px;
        margin-top: -3px;
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.FinSliderInput_range {
    width: 100px;
    height: 10px;
    -webkit-appearance: none;
    background: #111;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: lightgreen;
        cursor: pointer;
        border: 4px solid #333;
        box-shadow: -407px 0 0 400px lightgreen;
        transition: all 0.5s ease-in-out;

        &:hover {
            background: darkgreen;
            box-shadow: -407px 0 0 400px darkgreen;
        }
    }

    &.light {
        background: #dcdcdc;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        &::-webkit-slider-thumb {
            border: 4px solid #fff;
        }
    }
}

.FinSliderInput_inputArea {
    background-color: transparent;
    width: 50px;
    height: 25px;
    border-radius: 0.25rem;
    padding: 0 1rem;

    color: white;
    border: 1px solid rgba(125, 125, 125, 1);

    &.light {
        color: black;
        border: 1px solid rgba(175, 175, 175, 1);
    }
}
