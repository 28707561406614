.LoadingAnimation_container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000; // 2000 to hide all
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    transition: opacity 100ms ease-in-out;
    opacity: 1;

    &.off {
        transition: opacity 500ms ease-in-out;
        opacity: 0;
        pointer-events: none;
    }

    & > span {
        position: absolute;
        margin-top: 220px;
        font-weight: 500;
        font-size: 16px;
    }
}

.innerLoading {
    width: 100%;
    max-width: 200px;
}
